import React from "react";
import { Input, Typography, Select } from "antd";
import styled from "styled-components"

const { Title, Paragraph, Text } = Typography;
class Step5 extends React.Component<any, any> {
  state = {
    volume: this.props.volume,
    showRequiredValidation: false
  }

  checkVolumeValidity = (volume) => {
    if (!volume || volume <= 0) {
      this.setState({
        showRequiredValidation: true
      });
      return false
    } else {
      this.setState({
        showRequiredValidation: false
      });
      return true
    }
  }

  validate =()=> {
    return this.checkVolumeValidity(this.state.volume)
  }

  onChange = (e) => {
    let volume = e.target.value;
    this.checkVolumeValidity(volume)
    this.setState({
      volume: volume,
    });
    this.props.changeVolumeCb(volume)
    
  }

  render() {
    return (
      <StepWrapper>
        <div className="step-title">
          <Title level={3}>Wie groß ist <br/> Ihr Volumen?</Title>
        </div>
        <Paragraph className="section-text">
            Das Volumen bestimmt welches Fahrzeug wir für Ihren Umzug benötigen. Hinweis: Ein durchschnittlicher 2 Personenhaushalt hat zwischen 20 und 30 Kubikmeter.
        </Paragraph><br/>
        <div className="input-wrapper"> 
          <Input 
            type="number"
            min="0"
            size="large"
            onChange={this.onChange}
            defaultValue={this.state.volume}
            suffix={<Text>m<sup>3</sup></Text>}
          />
          { this.state.showRequiredValidation && <div>
              <Text type="danger">Bitte Volumen eingeben.</Text>
            </div>
          }
        </div>
       
      </StepWrapper>
    );
  }
}

export default Step5;

const StepWrapper = styled.div`
  .input-wrapper {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none!important;
      margin: 0!important;
    }
    input[type=number] {
      -moz-appearance: textfield!important;
    }
  }
  
`