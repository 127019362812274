import React from "react";
import { Input, Typography, Checkbox, Select } from "antd";
import styled from "styled-components"

const { Option } = Select;

const { Title, Paragraph, Text } = Typography;
class Step9 extends React.Component<any, any> {
  state = {
    personalInformation: this.props.personalInformation,
    showSalutationValidation: false,
    showFirstNameValidation: false,
    showLastNameValidation: false,
    showPhoneNumberValidation: false,
    showEmailValidation: false,
    showEmailFormatValidation: false,
    showAgreementValidation: false
  }

  onChange = (key, value) => {
    let personalInformation  = {...this.state.personalInformation}
    value = typeof value === "string" ? value.trim() : value
    personalInformation[key] = value
    this.setState({personalInformation})
    this.props.personalInformationChangedCb(personalInformation)

    if (key === "salutation" && value !== "") {
      this.setState({showSalutationValidation: false})
    }
    if (key === "firstName" && value !== "") {
      this.setState({showFirstNameValidation: false})
    }
    if (key === "lastName" && value !== "") {
      this.setState({showLastNameValidation: false})
    }
    if (key === "phone" && value !== "") {
      this.setState({showPhoneNumberValidation: false})
    }
    if (key === "email" && value !== "") {
      this.setState({showEmailValidation: false})
    }
    if (key === "email" && value !== "" && this.validateEmail(value)) {
      this.setState({showEmailFormatValidation: false})
    }
    if (key === "dataProtectionTermAgreement" && value !== "") {
      this.setState({showAgreementValidation: false})
    }
  }

  validateEmail = (email) => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  }

  validate =() => {
    let retVal = true
    let personalInformation = this.state.personalInformation;
    
    if (personalInformation.salutation) {
      this.setState({showSalutationValidation: false})
      retVal ? true  : false
    } else {
      this.setState({showSalutationValidation: true})
      retVal = false
    }
    if (personalInformation.firstName) {
      this.setState({showFirstNameValidation: false})
      retVal ? true  : false
    } else {
      this.setState({showFirstNameValidation: true})
      retVal = false
    }
    if (personalInformation.lastName) {
      this.setState({showLastNameValidation: false})
      retVal ? true  : false
    } else {
      this.setState({showLastNameValidation: true})
      retVal = false
    }
    if (personalInformation.phone) {
      this.setState({showPhoneNumberValidation: false})
      retVal ? true  : false
    } else {
      this.setState({showPhoneNumberValidation: true})
      retVal = false
    }
    if (personalInformation.email) {
      this.setState({showEmailValidation: false})
      retVal ? true  : false
      if (this.validateEmail(personalInformation.email)) {
        this.setState({showEmailFormatValidation: false})
        retVal ? true  : false
      } else {
        this.setState({showEmailFormatValidation: true})
        retVal = false
      }
    } else {
      this.setState({showEmailValidation: true})
      retVal = false
    }
    if (personalInformation.dataProtectionTermAgreement) {
      this.setState({showAgreementValidation: false})
      retVal ? true  : false
    } else {
      this.setState({showAgreementValidation: true})
      retVal = false
    }
    return retVal
  }

  render() {
    return (
      <StepWrapper>
        <div className="step-title">
          <Title level={3}>Fast geschafft.</Title>
        </div>
        <Paragraph className="section-text">
          Wenn Sie uns jetzt noch Ihren Namen und ein paar Kontaktdaten <br className="hide-sm"/> nennen, erhalten Sie Ihr Umzugs-Angebot.
        </Paragraph><br/>
        <div className="input-wrapper"> 
          <Select style={{ width: '100%' }} size="large" placeholder="Anrede" onChange={(e) => {this.onChange("salutation", e)}} defaultValue={this.state.personalInformation.salutation}>
            <Option value="Frau">Frau</Option>
            <Option value="Herr">Herr</Option>
          </Select>
          { this.state.showSalutationValidation && <div>
              <Text type="danger">Bitte wählen Sie die Anrede.</Text>
            </div>
          }    
        </div>
        <div className="input-wrapper"> 
          <Input size="large" placeholder="Vorname:" onChange={(e) => {this.onChange("firstName", e.target.value)}}
            defaultValue={this.state.personalInformation.firstName}/>
          { this.state.showFirstNameValidation && <div>
              <Text type="danger">Bitte Vorname angeben.</Text>
            </div>
          }    
        </div>
        <div className="input-wrapper"> <Input size="large" placeholder="Nachname:"onChange={(e) => {this.onChange("lastName", e.target.value)}}
            defaultValue={this.state.personalInformation.lastName}/>
            { this.state.showLastNameValidation && <div>
              <Text type="danger">Bitte Nachname angeben.</Text>
            </div>
          }  </div>
        <div className="input-wrapper"> <Input size="large" type="number" placeholder="Telefon:"onChange={(e) => {this.onChange("phone", e.target.value)}}
            defaultValue={this.state.personalInformation.phone}/>
          { this.state.showPhoneNumberValidation && <div>
              <Text type="danger">Bitte Telefonnummer angeben.</Text>
            </div>
          }
        </div>
        <div className="input-wrapper"> <Input size="large" placeholder="E-Mail:" onChange={(e) => {this.onChange("email", e.target.value)}}
            defaultValue={this.state.personalInformation.email}/>
            { this.state.showEmailValidation && <div>
              <Text type="danger">Bitte E-Mail eingeben.</Text>
            </div>
          }
          { this.state.showEmailFormatValidation && <div>
            <Text type="danger">Bitte gültige E-Mail eingeben.</Text>
          </div>
        }</div>
        <Checkbox className="declaration-radio" checked={this.state.personalInformation.dataProtectionTermAgreement} onChange={(e) => {this.onChange("dataProtectionTermAgreement", e.target.checked)}} >
          Ich stimme den Datenschutzbedingungen zu.
        </Checkbox>
        { this.state.showAgreementValidation && <div>
            <Text type="danger">Bitte Vereinbarung auswählen.</Text>
          </div>
        }
      </StepWrapper>
    );
  }
}

export default Step9;

const StepWrapper = styled.div`
  .ant-input::placeholder, .ant-select-selection-placeholder {
    color: #777777;
    font-weight: 500;
    opacity: 1;
  }
  .declaration-radio {
    font-size: 16px;
    @media (min-width: 1500px) {
      font-size: calc(13px * 1.4)!important;
    }
  }
  input::-webkit-inner-spin-button, 
  input::-webkit-outer-spin-button { 
    -webkit-appearance: none!important; 
    margin: 0!important; 
  }
  input[type=number] {
    -moz-appearance: textfield!important;
  }
}
`