import React from "react"
import { Typography, Collapse, Input } from "antd"
import { DownOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons"
import styled from "styled-components"

const { Panel } = Collapse
const { Title, Text } = Typography

let movingList = [
{
  name: 'Wohnzimmer',
  items: [
    {name: 'Sofa, 2 Sitzer', count: 0}, 
    {name: 'Sofa, 3 Sitzer', count: 0},
    {name: 'Sofa, 4 Sitzer', count: 0},
    {name: 'Sofa, 5 Sitzer', count: 0},
    {name: 'Couchtisch (mittel)', count: 0},
    {name: 'Fernseher, Flachbild (mittel)', count: 0},
    {name: 'Regal, Bücherregal (mittel)', count: 0},
    {name: 'Regal, Bücherregal (groß)', count: 0},
    {name: 'TV Schrank/TV Bank', count: 0},
    {name: 'Teppich (groß)', count: 0},
    {name: 'Tisch, Schreibtisch (mittel)', count: 0},
    {name: 'Lampe, Stehlampe', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Schlafzimmer',
  items: [
    {name: 'Tisch, Nachttisch', count: 0}, 
    {name: 'Bett, Einzelbett', count: 0},
    {name: 'Bett, Doppelbett', count: 0},
    {name: 'Bett 1,60m', count: 0},
    {name: 'Kleiderschrank (offen)', count: 0},
    {name: 'Kleiderschrank 2 Türen', count: 0},
    {name: 'Kleiderschrank 4 Türen', count: 0},
    {name: 'Kommode (mittel)', count: 0},
    {name: 'Fernseher, Flachbild (mittel)', count: 0},
    {name: 'Tisch, Schreibtisch (mittel)', count: 0},
    {name: 'Stuhl, Bürostuhl', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Esszimmer',
  items: [
    {name: 'Spiegel (groß)', count: 0},
    {name: 'Sideboard (mittel)', count: 0},
    {name: 'Sideboard (klein)', count: 0},
    {name: 'Stuhl', count: 0},
    {name: 'Teppich (groß)', count: 0},
    {name: 'Gemälde (groß)', count: 0},
    {name: 'Tisch, Esstisch (groß)', count: 0},
    {name: 'Tisch, Esstisch (mittel)', count: 0},
    {name: 'Tisch, Esstisch (klein)', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Küche',
  items: [
    {name: 'Kühlschrank (mittel)', count: 0},
    {name: 'Kühlschrank (groß)', count: 0},
    {name: 'Waschmaschine', count: 0},
    {name: 'Mikrowelle', count: 0},
    {name: 'Herd / Ofen', count: 0},
    {name: 'Spülmaschine', count: 0},
    {name: 'Tisch, Küchentisch (klein)', count: 0},
    {name: 'Tisch, Küchentisch (mittel)', count: 0},
    {name: 'Tisch, Küchentisch (groß)', count: 0},
    {name: 'Stuhl', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Kinderzimmer',
  items: [
    {name: 'Schrank', count: 0},
    {name: 'Hochbett', count: 0},
    {name: 'Bett', count: 0},
    {name: 'Schreibtisch', count: 0},
    {name: 'Stuhl', count: 0},
    {name: 'Regal', count: 0},
    {name: 'Sofa', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Büro',
  items: [
    {name: 'Schreibtisch', count: 0},
    {name: 'Sofa', count: 0},
    {name: 'Sessel', count: 0},
    {name: 'Schrank', count: 0},
    {name: 'Regal', count: 0},
    {name: 'Rollcontainer', count: 0},
    {name: 'Stuhl', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Keller/Garage',
  items: [
    {name: 'Regal', count: 0},
    {name: 'Schrank', count: 0},
    {name: 'Reifen', count: 0},
    {name: 'Fahrrad', count: 0},
    {name: 'Kinderwagen', count: 0},
    {name: 'Gartengerät', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Bad/Flur',
  items: [
    {name: 'Regal', count: 0},
    {name: 'Schrank', count: 0},
    {name: 'Garderobe', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Sonstiges',
  items: [
    {name: 'Fahrrad (Erwachsene)', count: 0},
    {name: 'Fahrrad (Kind)', count: 0},
    {name: 'Motorrad', count: 0},
    {name: 'Tischfußball', count: 0},
    {name: 'Tisch, Gartentisch', count: 0},
    {name: 'others', value: null, count: 0}
  ]
},{
  name: 'Kartons',
  items: [
    {name: 'Karton, Umzugskarton (S)', count: 0},
    {name: 'Karton, Umzugskarton (M)', count: 0},
    {name: 'Karton, Umzugskarton (L)', count: 0},
    {name: 'Karton, Umzugskarton (XL)', count: 0},
    {name: 'Koffer (groß)', count: 0},
    {name: 'Koffer (klein)', count: 0},
    {name: 'Müllsack (groß), gefüllt', count: 0},
    {name: 'Plastiktasche (klein), gefüllt', count: 0},
    {name: 'others', value: null, count: 0}
  ]
}]

class Step6 extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.props.updateMovingItemListCb(movingList)
    this.state = {
      movingList : this.props.movingList,
      showRequiredValidation: false
    }
  }

  onOtherValueChange = (category, item, key, value) => {
    movingList[category].items[item].value = value.trim();
    if (value.trim() === "") {
      movingList[category].items[item].count = 0
      this.props.updateMovingItemListCb(movingList)
    }
  }

  validate = () => {
    for (let listItem of movingList) {
      for (let item of listItem.items) {
        if (item.count > 0) {
          this.setState({
            showRequiredValidation: false
          });
          return true
        }
      }
    }
    this.setState({
      showRequiredValidation: true
    });
    return false;
  }

  onMovingListUpdate = list => {
    this.props.updateMovingItemListCb(list)
  }

  incrementItemCount = (category, item, itemObj) => {
    if (itemObj.name === "others") {
      if (movingList[category].items[item].value === "" || movingList[category].items[item].value === null ) {
        return
      }
    }
    movingList[category].items[item].count = movingList[category].items[item].count + 1;
    this.props.updateMovingItemListCb(movingList)
  }
  
  decrementItemCount = (category, item, itemObj) => {
    if (movingList[category].items[item].count) {
      movingList[category].items[item].count = movingList[category].items[item].count - 1;
      this.props.updateMovingItemListCb(movingList)
    }
  }

  render() {
    return (
      <StepWrapper>
        <div className="step-title">
          <Title level={3}>
            Was dürfen wir für Sie <br /> umziehen?
          </Title>
        </div>

        <Collapse
          defaultActiveKey={["0"]}
          expandIconPosition={"right"}
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? 180 : 0} />
          )}
        > 
         { movingList.map((category, i) =>
            <Panel header={category.name} key={i}>

              { category.items.map((item, j) =>
                <OptionItem key={i*10+j} onClick={() => this.incrementItemCount(i, j, item)}>
                  {
                    item.name === "others" ?  
                    <Input className="input-others" defaultValue={item.value} onClick={(e) => {e.stopPropagation()}} size="large" placeholder="Sonstiges" onChange={(e) => {this.onOtherValueChange(i, j,"others", e.target.value)}} /> : 
                    <span>{item.name}</span>
                  }
                 
                  <span> 
                    { movingList[i].items[j].count !== 0 && 
                      <span><MinusOutlined onClick={(e) => {e.stopPropagation(); this.decrementItemCount(i, j, item)}}/> 
                        <span>{item.count}</span>
                      </span>
                    } 
                    <PlusOutlined onClick={(e) => {e.stopPropagation(); this.incrementItemCount(i, j, item)}}/>
                  </span>
                </OptionItem>
              )}
            </Panel>
          )}
        </Collapse>
        { this.state.showRequiredValidation && <div>
            <Text type="danger">Bitte wählen Sie eine Anzahl aus.</Text>
          </div>
        }
      </StepWrapper>
    )
  }
}

export default Step6

const StepWrapper = styled.div`
  .ant-collapse {
    border: none;

    .ant-collapse-item {
      margin-bottom: 12px;
      border: none;
      border-radius: 7px;

      .ant-collapse-header {
        background-color: black;
        color: white !important;
        font-weight: 500;
        padding: 10px 16px;
        border-radius: 7px;

        .anticon {
          color: white;
        }
      }
      .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }
`

const OptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #000000;
  padding: 10px 16px;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  border-radius: 7px;
  transition: all .2s ease-in-out;

  :hover {
    background-color: #ffecb5;
  }

  span span {
    margin-left: 10px;
  }

  .option-icon {
    font-size: 18px;
    @media (min-width: 1500px) {
      font-size: calc(16px * 1.4)!important;
    }
  }

  .input-others{
    width: 70%;
    font-weight: 600!important;
  }
`
