import React from "react";
import { Input, Typography, Select } from "antd";
import { SearchOutlined} from '@ant-design/icons';
import styled from "styled-components"
import PlacesAutocomplete from 'react-places-autocomplete';

const searchOptions = {
  types: ['(cities)'],
  componentRestrictions: {country: ["de","at","ch"]}
}

const { Option } = Select;

const { Title, Paragraph, Text } = Typography;
class Step2 extends React.Component<any, any> {
  state = {
    place : this.props.place,
    apartment: null,
    showApartmentSelection: this.props.place.type === "Wohnung",
    showCityValidation: false,
    showTypeValidation: false,
    showApartmentValidation: false,
    showParkingValidation: false,
  }

  handleSelect = city => {
    let place  = this.state.place
    place.city = city !== null ? city.trim() : city
    this.setState({place})
  }

  onChange = (key, value) => {
    let place  = this.state.place
    place[key] = typeof value === "string" ? value.trim() : value
    this.setState({place})

    if (this.state.place.type === "Wohnung") {
      this.setState({showApartmentSelection: true})
    } else {
      this.setState({showApartmentSelection: false})
    }

    if (key === "city" && value !== "") {
      this.setState({showCityValidation: false})
    }
    if (key === "type" && value !== "") {
      this.setState({showTypeValidation: false})
    }
    if (key === "apartment" && value !== "") {
      this.setState({showApartmentValidation: false})
    }
    if (key === "parkingFacility" && value !== "") {
      this.setState({showParkingValidation: false})
    }

    this.props.toPlaceSelectedCb(place)
  }

  validate = () => {
    let retVal = true
    let place = this.state.place;
    
    if (place.city) {
      this.setState({showCityValidation: false})
      retVal ? true  : false
    } else {
      this.setState({showCityValidation: true})
      retVal = false
    }
    if (place.type) {
      this.setState({showTypeValidation: false})
      retVal ? true  : false
      if (place.apartment) {
        this.setState({showApartmentValidation: false})
        retVal ? true  : false
      } 
      if(place.apartment === null && place.type === "Wohnung") {
        this.setState({showApartmentValidation: true})
        retVal = false
      }
    } else {
      this.setState({showTypeValidation: true})
      retVal = false
    }
    
    if (place.parkingFacility) {
      this.setState({showParkingValidation: false})
      retVal ? true  : false
    } else {
      this.setState({showParkingValidation: true})
      retVal = false
    }
    return retVal
  }

  render() {
    return (
      <StepWrapper>
        <div className="step-title">
          <Title level={3}>Wohin möchten Sie <br/> umziehen?</Title>
        </div>
        <div className="input-wrapper"> 
          <PlacesAutocomplete searchOptions={searchOptions} value={this.state.place.city} onChange={(e) => {this.onChange("city", e)}} onSelect={this.handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="search-wrapper">
                <Input {...getInputProps({
                  className: 'location-search-input',
                })}  size="large" placeholder="Wohnort" suffix={<SearchOutlined className="input-icon"/>} defaultValue={this.state.place.city} onPressEnter={(e) => {this.props.formSubmitCb()}} />
                
                <section className="autocomplete-dropdown-container">
                  {loading && <section>Loading...</section>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                      
                    const style = suggestion.active
                      ? { backgroundColor: '#f0f0f0', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <section
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <div className="list-item">{suggestion.description}</div>
                      </section>
                    );
                  })}
                </section>
              </div>
            )}
          </PlacesAutocomplete>
          { this.state.showCityValidation && <div>
              <Text type="danger">Bitte Wohnort eingeben.</Text>
            </div>
          }
        </div>
        
        <div className="input-wrapper"> 
          <Select style={{ width: '100%' }} size="large" placeholder="Art" onChange={(e) => {this.onChange("type", e)}} defaultValue={this.state.place.type} >
            <Option value="Wohnung">Wohnung</Option>
            <Option value="Haus">Haus</Option>
            <Option value="Büro">Büro</Option>
            <Option value="art">Lager</Option>
          </Select>
          { this.state.showTypeValidation && <div>
              <Text type="danger">Bitte wählen Sie Art.</Text>
            </div>
          }
        </div>

        { this.state.showApartmentSelection && 
          <div className="input-wrapper"> 
            <Select style={{ width: '100%' }} size="large" placeholder="Stockwerk auswählen" onChange={(e) => {this.onChange("apartment", e)}} defaultValue={this.state.place.apartment}>
              <Option value="UG">UG</Option>
              <Option value="EG">EG</Option>
              <Option value="1. Stock">1. Stock</Option>
              <Option value="2. Stock">2. Stock</Option>
              <Option value="Stock 3-5">Stock 3-5</Option>
              <Option value="Stock 6-10">Stock 6-10</Option>
              <Option value="ab Stock 10">ab Stock 10</Option>
            </Select>
            { this.state.showApartmentValidation && <div>
                <Text type="danger">Bitte Stockwerk auswählen.</Text>
              </div>
            }
        </div>
        }
        
        <div className="input-wrapper"> 
          <Select style={{ width: '100%' }} size="large" placeholder="Parkmöglichkeiten" onChange={(e) => {this.onChange("parkingFacility", e)}} defaultValue={this.state.place.parkingFacility}>
            <Option value="Direkt vor der Tür">Direkt vor der Tür</Option>
            <Option value="10 Meter entfernt">10 Meter entfernt</Option>
            <Option value="25 Meter entfernt">25 Meter entfernt</Option>
            <Option value="50 Meter entfernt">50 Meter entfernt</Option>
            <Option value="über 100 Meter entfernt">über 100 Meter entfernt</Option>
          </Select>
          { this.state.showParkingValidation && <div>
              <Text type="danger">Bitte wählen Sie eine Parkmöglichkeit.</Text>
            </div>
          }
        </div>
       
      </StepWrapper>
    );
  }
}

export default Step2;

const StepWrapper = styled.div`
.search-wrapper {
  position: relative;

  .autocomplete-dropdown-container {
    position: absolute;
    left: 0;
    right:0;
    z-index: 1050;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    
    .list-item {
      padding: 6px 10px;
    }
  }
}
`
