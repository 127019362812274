import React from "react"
import { navigate } from 'gatsby'
import styled from "styled-components"
import SEO from "../components/Seo"
import { FormContents } from "../components/form/FormContents"
import FormHeader from "../components/form/FormHeader"
import FormFooter from "../components/form/FormFooter"

interface IProps {
}

interface IState {
  currentStep?: number;
}

export default class FormPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      currentStep: 0
    };
  }
  previousPageCbFunction = () => {
    if (this.state.currentStep === 0) {
      navigate('/');
    } else {
      this.setState({currentStep: this.state.currentStep - 1})
    }
  }
  nextPageCbFunction = (obj) => {
    if (obj.submitted) {
      this.setState({currentStep: 0 })
    } else {
      this.setState({currentStep: this.state.currentStep + 1 })
    }
  }
  public render() {
    return (
      <LayoutContainer>
        <SEO title="Contact" />
        <FormHeader previousPageCallBack={this.previousPageCbFunction}></FormHeader>
        <FormContents nextPageCallBack={this.nextPageCbFunction} currentStep = {this.state.currentStep}/>
        <FormFooter></FormFooter>
      </LayoutContainer>
    )
  }
}

const LayoutContainer = styled.div`
  min-height: 100vh;
  position: relative;

  section .wrapper {
    margin: 0 auto;
    max-width: 800px;
    @media (min-width: 1500px) {
      max-width: calc(800px * 1.4);
    }
    padding: 1.0875rem 1.0875rem 1.45rem;
  }
  .vertical-space-10 {
    height: 10px;
  }
`
