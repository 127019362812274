import React from "react"
import { Typography, Row, Col  } from "antd"
import { Link } from "gatsby"
import styled from "styled-components"

const { Title, Paragraph, Text } = Typography
export default class FormFooter extends React.Component {
  public render() {
    return (
      <FooterContainer>
        <section className="footer-section">
          <div className="wrapper">
              <div className="aligned-center">
              <Link to="/datenschutz/"> <span>Datenschutz</span> </Link>  |  <Link to="/impressum/"> <span>Impressum</span></Link>
              </div>
          </div>
        </section>
      </FooterContainer>
    )
  }
}

const FooterContainer = styled.footer`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  .footer-section .wrapper{
    padding: 0;
    height: 80px;
    line-height: 80px;
  }
  .aligned-center{
      text-align: center;
  }
  a {
    color: rgba(0,0,0,0.65);
    :hover {
      color: #ffc927;
    }
  }
`