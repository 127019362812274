import React from "react"
import { Typography } from "antd"
import { Row, Col } from "antd"
import styled from "styled-components"
import StrikedText from "../StrikedText"

const logoIcon = require("../../images/logo.png");
const mobileIcon = require("../../images/mobile_icon.png")

const { Title } = Typography
export default class FormHeader extends React.Component<any, any> {
  back = () => {
    this.props.previousPageCallBack()
  }
  public render() {
    return (
      <HeaderContainer>
        <section className="form-header-section">
          <div className="wrapper">
            <Row>
              <Col span={9} className="aligned-1">
                <BackButton>
                  <Title level={4}>
                    <a href="#" className="back-link" onClick={this.back}>&lt; &nbsp;zurück</a>
                  </Title>
                </BackButton>
              </Col>
              <Col span={6}>
                <div className="logo-wrapper">
                  <img src={logoIcon} alt="Image not found"/>
                </div>
              </Col>
              <Col span={9} xs={0} sm={0} md={9} className="aligned-2">
                <Title level={4}>
                  <StrikedText
                    text="07141 9163600"
                    textColor="#ffc927"
                    strikeColor="black"
                    type="tel"
                  />
                </Title>
              </Col>
              <Col span={9} xs={9} sm={9} md={0} className="aligned-2">
                <a href="tel:07141 9163600" className="icon-wrapper">
                  <img src={mobileIcon} alt="Image not found"/>
                </a>
              </Col>
            </Row>
          </div>
        </section>
      </HeaderContainer>
    )
  }
}

const HeaderContainer = styled.header`
  .aligned-1 {
    text-align: left;
  }
  .aligned-2 {
    text-align: right;
  }
  .icon-wrapper {
    img {
      width: 24px;
    }
  }

  .logo-wrapper {
    max-width: 90px;
    width: 100%;
    margin: 0 auto;

    img {
      width: inherit;
      opacity: 0.2;
    }
  }
`
const BackButton = styled.div`
  cursor: pointer;
  .back-link {
    color: #000000!important;
    transition: all .2s ease-in-out;
    :hover, :focus {
      color: #ffc927!important;
    }
  }
  
`
