import React from "react"
import { Typography, DatePicker } from "antd"
import styled from "styled-components"
import { Select, Radio } from "antd"
import locale from 'antd/es/date-picker/locale/de_DE';
import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

const { Option } = Select

const { Title, Paragraph, Text } = Typography

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days');
}

class Step3 extends React.Component<any, any> {
  state = {
    value: this.props.haveMovingDate,
    showRequiredValidation: false,
    showDateValidation: false
  }

  onChange = e => {
    this.setState({
      value: e.target.value,
      showRequiredValidation: false
    });
    if (!e.target.value) {
      this.setState({
        showDateValidation: false
      });
    }

    this.props.movingDateSelectedCb(e.target.value)
  };

  onDateChange = (date, dateString) => {
    this.setState({showDateValidation: false})
    this.props.changedDateValueCb(dateString)
  };

  validate =()=> {
    if (this.state.value === false) {
      return true
    }
    if (this.state.value === null) {
      this.setState({showRequiredValidation: true})
      return false
    } else {
      this.setState({showRequiredValidation: false})
      if (!this.props.movingDate) {
        this.setState({
          showDateValidation: true
        });
        return false
      } else {
        return true
      }
    }
  }

  render() {
    const { value } = this.state;
    return (
      <StepWrapper>
        <div className="step-title">
          <Title level={3}>
            Haben Sie bereits ein <br /> Umzugsdatum?
          </Title>
        </div>

        <div className="input-wrapper">
          <Radio.Group onChange={this.onChange} value={value}>
            <Radio value={true}>Ja, ich habe ein Umzugsdatum.</Radio><br/>
            { this.state.value && 
              <DatePicker
                locale={locale}
                onChange={this.onDateChange}
                disabledDate={disabledDate} 
                className="datepicker"
                defaultValue={ this.props.movingDate && moment(this.props.movingDate, 'DD.MM.YYYY')} 
                format={'DD.MM.YYYY'}
              /> 
            }
            <Radio value={false}>Nein, ich habe kein Umzugsdatum.</Radio>
          </Radio.Group>
          { this.state.showRequiredValidation && <div>
              <Text type="danger">Bitte treffen Sie eine Auswahl.</Text>
            </div>
          }
          { this.state.showDateValidation && <div>
              <Text type="danger">Bitte wählen Sie ein Datum.</Text>
            </div>
          }
        </div>
      </StepWrapper>
    )
  }
}

export default Step3

const StepWrapper = styled.div`
  .datepicker {
    width: 100%;
    margin-bottom: 12px;
    padding: 8px 14px;
    border-radius: 7px;

    .ant-picker-input > input {
      color: #000000;
      font-weight: 600;
    }
  }
`