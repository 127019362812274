import React, { Component } from "react";
import { Input, Typography } from "antd";
import { SearchOutlined} from '@ant-design/icons';
import styled from "styled-components"
import { Select, Radio } from 'antd';

const { Option } = Select;

const { Title, Paragraph, Text } = Typography;
class Step7 extends React.Component<any, any> {
  state = {
    value: this.props.needAdditionalServices,
    showRequiredValidation: false,
  }

  onChange = e => {
    this.setState({
      value: e.target.value,
      showRequiredValidation: false
    });
    this.props.needAdditionalServicesCb(e.target.value)
  }

  validate =()=> {
    if (this.state.value === null) {
      this.setState({showRequiredValidation: true})
      return false
    } else {
      return true
    }
  }

  render() {
    const { value } = this.state;
    return (
      <StepWrapper>
        <div className="step-title">
          <Title level={3}>Möchten Sie <br/> zusätzliche Services?</Title>
        </div>
        <div className="input-wrapper">
          <Radio.Group onChange={this.onChange} value={value}>
            <Radio value={true}>Ja, bitte.</Radio><br/>
            <Radio value={false}>Nein, danke.</Radio>
          </Radio.Group>
          { this.state.showRequiredValidation && <div>
              <Text type="danger">Bitte treffen Sie eine Auswahl.</Text>
            </div>
          }
        </div>
      </StepWrapper>
    );
  }
}

export default Step7;

const StepWrapper = styled.div`
  
  
`