import React from "react"
import { Steps, Button, Typography, Form, Spin, Alert } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import styled from "styled-components"

import Step1 from "./steps/Step1"
import Step2 from "./steps/Step2"
import Step3 from "./steps/Step3"
import Step4 from "./steps/Step4"
import Step5 from "./steps/Step5"
import Step6 from "./steps/Step6"
import Step7 from "./steps/Step7"
import Step8 from "./steps/Step8"
import Step9 from "./steps/Step9"

const { Title, Paragraph, Text } = Typography
const { Step } = Steps

declare global {
  interface Window {
    dataLayer: any
  }
}
export class FormContents extends React.Component<any, any> {
  step1: any = React.createRef()
  step2: any = React.createRef()
  step3: any = React.createRef()
  step4: any = React.createRef()
  step5: any = React.createRef()
  step6: any = React.createRef()
  step7: any = React.createRef()
  step9: any = React.createRef()

  formRef: any = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      loading: false,
      showError: false,
      fromPlace: {
        city: null,
        type: null,
        apartment: null,
        parkingFacility: null,
      },
      toPlace: {
        city: null,
        type: null,
        apartment: null,
        parkingFacility: null,
      },
      volumeCalculated: null,
      volume: null,
      haveMovingDate: null,
      date: "",
      movingItemList: null,
      needAdditionalServices: null,
      additionalServices: {
        packingAndUnpacking: false,
        furnitureAssemblyAndDismantling: false,
        setUpNoParkingZone: false,
        packagingMaterial: false,
        heavyObjectTransport: false,
      },
      personalInformation: {
        salutation: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        dataProtectionTermAgreement: null,
      },
    }
  }

  validateForm = step => {
    let retVal = true
    switch (step + 1) {
      case 1:
        retVal = this.step1.current.validate()
        break
      case 2:
        retVal = this.step2.current.validate()
        break
      case 3:
        retVal = this.step3.current.validate()
        break
      case 4:
        retVal = this.step4.current.validate()
        break
      case 5:
        if (this.state.volumeCalculated) {
          retVal = this.step5.current.validate()
        } else {
          retVal = this.step6.current.validate()
        }
        break
      case 6:
        retVal = this.step7.current.validate()
        break
      case 7:
        if (!this.state.needAdditionalServices) {
          retVal = this.step9.current.validate()
        } else {
          //retVal = this.step7.current.validate();
          retVal = true
        }
        break
      case 8:
        retVal = this.step9.current.validate()
        break

      default:
        retVal = true
        break
    }
    return retVal
  }

  getMovingList = movingList => {
    let list = []
    for (let listItem of movingList) {
      let itemList = []
      for (let item of listItem.items) {
        let otherObj = { name: "", count: 0 }
        if (item.count > 0) {
          if (item.name === "others") {
            otherObj.name = "Sonstiges: " + item.value
            otherObj.count = item.count
            itemList.push(otherObj)
          } else {
            itemList.push(item)
          }
        }
      }
      if (itemList.length > 0) {
        list.push({ name: listItem.name, items: itemList })
      }
    }
    return list
  }

  mailerRestApiCall = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstName: this.state.personalInformation.firstName,
        lastName: this.state.personalInformation.lastName,
        salutation: this.state.personalInformation.salutation,
        phone: this.state.personalInformation.phone
          ? this.state.personalInformation.phone
          : "",
        email: this.state.personalInformation.email,
        dataProtectionTermAgreement: this.state.personalInformation
          .dataProtectionTermAgreement,
        fromPlace: {
          city: this.state.fromPlace.city,
          type: this.state.fromPlace.type,
          apartment: this.state.fromPlace.apartment
            ? this.state.fromPlace.apartment
            : "",
          parkingFacility: this.state.fromPlace.parkingFacility,
        },
        toPlace: {
          city: this.state.toPlace.city,
          type: this.state.toPlace.type,
          apartment: this.state.toPlace.apartment
            ? this.state.toPlace.apartment
            : "",
          parkingFacility: this.state.toPlace.parkingFacility,
        },
        haveMovingDate: this.state.haveMovingDate,
        movingDate: this.state.date,
        volumeCalculated: this.state.volumeCalculated,
        volume: this.state.volume ? parseInt(this.state.volume) : 0,
        movingList: this.state.movingItemList
          ? this.getMovingList(this.state.movingItemList)
          : [],
        needAdditionalServices: this.state.needAdditionalServices,
        additionalServices: this.state.additionalServices,
      }),
    }

    return fetch("https://api.easypak-umzug.de/mailer", requestOptions)
  }

  deleteFromArray = (arrayList, key) => {
    arrayList.splice(key, 1)
  }

  next = steps => {
    if (!this.validateForm(this.props.currentStep)) {
      return
    }
    if (this.props.currentStep === steps.length - 1) {
      this.setState({ loading: true })
      this.mailerRestApiCall()
        .then(res => res)
        .then(
          result => {
            window.dataLayer.push({ event: "event_abschluss" })

            this.setState({ loading: false, showError: false, submitted: true })
            this.props.nextPageCallBack({ submitted: true })
          },
          error => {
            this.setState({ loading: false, showError: true })
            setTimeout(() => {
              this.setState({ showError: false })
            }, 5000)
          }
        )
    } else {
      this.props.nextPageCallBack({ submitted: false })
    }
  }

  volumeCalculatedCb = value => {
    this.setState({ volumeCalculated: value })
  }

  changeVolumeCb = value => {
    this.setState({ volume: value })
  }

  updateMovingItemListCb = value => {
    this.setState({ movingItemList: value })
  }

  movingDateSelectedCb = value => {
    this.setState({ haveMovingDate: value })
  }

  changedDateValueCb = value => {
    this.setState({ date: value })
  }

  needAdditionalServicesCb = value => {
    this.setState({ needAdditionalServices: value })
  }

  selectAdditionalServicesCb = value => {
    this.setState({ additionalServices: value })
  }

  personalInformationChangedCb = value => {
    this.setState({ personalInformation: value })
  }

  fromPlaceSelectedCb = value => {
    this.setState({ fromPlace: value })
  }

  toPlaceSelectedCb = value => {
    this.setState({ toPlace: value })
  }

  formSubmitCb = () => {
    this.formRef.current.submit()
  }

  render() {
    const tempSteps = [
      <Step1
        ref={this.step1}
        fromPlaceSelectedCb={this.fromPlaceSelectedCb}
        place={this.state.fromPlace}
        formSubmitCb={this.formSubmitCb}
      />,
      <Step2
        ref={this.step2}
        toPlaceSelectedCb={this.toPlaceSelectedCb}
        place={this.state.toPlace}
        formSubmitCb={this.formSubmitCb}
      />,
      <Step3
        ref={this.step3}
        movingDateSelectedCb={this.movingDateSelectedCb}
        haveMovingDate={this.state.haveMovingDate}
        changedDateValueCb={this.changedDateValueCb}
        movingDate={this.state.date}
      />,
      <Step4
        ref={this.step4}
        volumeCalculatedCb={this.volumeCalculatedCb}
        volumeCalculated={this.state.volumeCalculated}
      />,
      <Step5
        ref={this.step5}
        volume={this.state.volume}
        changeVolumeCb={this.changeVolumeCb}
      />,
      <Step6
        ref={this.step6}
        updateMovingItemListCb={this.updateMovingItemListCb}
        movingList={this.state.movingItemList}
      />,
      <Step7
        ref={this.step7}
        needAdditionalServicesCb={this.needAdditionalServicesCb}
        needAdditionalServices={this.state.needAdditionalServices}
      />,
      <Step8
        selectAdditionalServicesCb={this.selectAdditionalServicesCb}
        additionalServices={this.state.additionalServices}
      />,
      <Step9
        ref={this.step9}
        personalInformationChangedCb={this.personalInformationChangedCb}
        personalInformation={this.state.personalInformation}
      />,
    ]
    var steps = []
    for (let step of tempSteps) {
      steps.push(step)
    }

    let current = this.props.currentStep

    if (this.state.volumeCalculated) {
      this.deleteFromArray(steps, 5)
    } else {
      this.deleteFromArray(steps, 4)
    }

    if (!this.state.needAdditionalServices) {
      this.deleteFromArray(steps, 6)
    }

    return (
      <FormContainer className="form-header-section">
        <Spin
          spinning={this.state.loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Form ref={this.formRef} onFinish={() => this.next(steps)}>
            <div className="wrapper">
              {!this.state.submitted && (
                <div className="steps-content">
                  {steps[current]}
                  {this.state.showError && (
                    <Alert
                      message="Server Fehler"
                      description="Bitte versuchen Sie es nach einiger Zeit erneut."
                      type="error"
                      showIcon
                    />
                  )}
                </div>
              )}

              {this.state.submitted && (
                <div className="steps-content">
                  <div className="step-title">
                    <Title level={3}>
                      Herzlichen Dank, <br />
                      {this.state.personalInformation.salutation}{" "}
                      {this.state.personalInformation.firstName}{" "}
                      {this.state.personalInformation.lastName}.
                    </Title>
                  </div>
                  <Paragraph className="section-text">
                    Innerhalb der nächsten 1-2 Werktage erhalten Sie unser{" "}
                    <br className="hide-sm" /> Angebot per E-Mail an:{" "}
                    {this.state.personalInformation.email}
                  </Paragraph>
                  <br />
                  <Paragraph className="section-text">
                    Bei Fragen können Sie uns auch jederzeit per{" "}
                    <br className="hide-sm" /> E-Mail unter{" "}
                    <Text strong underline>
                      {" "}
                      <a
                        className="highlighted-text"
                        href="mailto:info@easypak-umzug.de"
                      >
                        {" "}
                        info@easypak-umzug.de
                      </a>{" "}
                    </Text>
                    oder telefonisch <br className="hide-sm" /> unter{" "}
                    <Text strong>
                      <a className="highlighted-text" href="tel:07141 9163600">
                        {" "}
                        07141 9163600
                      </a>
                    </Text>{" "}
                    erreichen.
                  </Paragraph>
                </div>
              )}
              <div className="steps-action">
                {current < steps.length && !this.state.submitted && (
                  <Form.Item>
                    <Button type="primary" size="large" htmlType="submit">
                      weiter
                    </Button>
                  </Form.Item>
                )}
              </div>
            </div>
          </Form>
        </Spin>
      </FormContainer>
    )
  }
}

const FormContainer = styled.section`
  @keyframes expand {
    from {
      transform: scale(0);
      opacity: 0;
    }
  }

  padding-bottom: 80px;

  .steps-content {
    max-width: 420px;
    margin: 0 auto;
    @media (min-width: 1500px) {
      max-width: calc(340px * 1.4);
    }
    div {
      animation: expand 0.25s ease-in-out;
    }
  }

  .steps-action {
    margin-top: 24px;
    text-align: center;
  }

  .step-title {
    text-align: center;
    margin-bottom: 32px;
  }

  .section-text {
    margin: 0 -60px;
    text-align: center;
  }

  .highlighted-text {
    color: #000000;
    &:hover {
      color: #ffc927;
    }
  }

  .input-wrapper {
    margin-bottom: 12px;
    .ant-input-lg {
      font-size: 18px;
      @media (min-width: 1500px) {
        font-size: calc(14px * 1.4) !important;
      }
      font-weight: 600;
    }
    .ant-select-selector {
      font-size: 18px;
      @media (min-width: 1500px) {
        font-size: calc(14px * 1.4) !important;
      }
      font-weight: 600;
      color: #000;
    }
    .input-icon {
      color: rgb(0, 0, 0, 0.65);
    }

    .ant-select-selection-placeholder {
      opacity: 1 !important;
      color: #777777 !important;
    }

    .ant-radio-group,
    .ant-checkbox-group {
      width: 100%;

      .ant-radio-wrapper,
      .ant-checkbox-wrapper {
        font-size: 18px;
        @media (min-width: 1500px) {
          font-size: calc(14px * 1.4) !important;
        }
        font-weight: 600;
        color: black;
        padding: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 7px;
        width: 100%;
        margin-bottom: 12px;

        &:hover {
          border: 1px solid #ffd94f;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .hide-sm {
      display: none;
    }
    .section-text {
      margin: 0;
    }
  }
`
