import React from "react";
import { Typography, Select, Checkbox } from "antd";
import styled from "styled-components"

const { Option } = Select;

const { Title, Paragraph, Text } = Typography;
const options = [
  { label: 'Ein- und Auspacken', value: 'packingAndUnpacking' },
  { label: 'Möbelab- und Aufbau', value: 'furnitureAssemblyAndDismantling' },
  { label: 'Halteverbotszone einrichten', value: 'setUpNoParkingZone' },
  { label: 'Verpackungsmaterial', value: 'packagingMaterial' },
  { label: 'Transport schwerer Gegenstände (z. B. ein Klavier)', value: 'heavyObjectTransport' }
];

class Step8 extends React.Component<any, any> {
  state = {
    additionalServices: this.props.additionalServices,
    selectedValues:null
  }
  onChange = (checkedValues) => {
    let additionalServices = this.state.additionalServices
    for (let service in additionalServices) {
      if (additionalServices.hasOwnProperty(service)) {
        additionalServices[service] = false
      }
    }
    for (let service of checkedValues) {
      if (additionalServices.hasOwnProperty(service)) {
        additionalServices[service] = true
      }
    }

    this.props.selectAdditionalServicesCb(additionalServices);
  }

  render() {
    let defaultOptions = [];
    for (var service in this.state.additionalServices) {
      if (this.state.additionalServices.hasOwnProperty(service)) {
        if (this.state.additionalServices[service]) {
          defaultOptions.push(service);
        }
      }
    }
    return (
      <StepWrapper>
        <div className="step-title">
          <Title level={3}>Welchen Zusatz-Service <br/> wü nschen Sie?</Title>
        </div>
        <div className="input-wrapper">
          <Checkbox.Group options={options} defaultValue={defaultOptions} onChange={this.onChange}/>
        </div>
       
      </StepWrapper>
    );
  }
}

export default Step8;

const StepWrapper = styled.div`
  
  
`