import React, { Component } from "react";
import { Typography } from "antd";
import styled from "styled-components"
import { Radio } from 'antd';

const { Title, Text } = Typography;
class Step4 extends React.Component<any, any> {
  state = {
    value: this.props.volumeCalculated,
    showRequiredValidation: false,
  }

  onChange = e => {
    this.setState({
      value: e.target.value,
      showRequiredValidation: false
    });
    this.props.volumeCalculatedCb(e.target.value)
  };

  validate =()=> {
    if (this.state.value === null) {
      this.setState({showRequiredValidation: true})
      return false
    } else {
      return true
    }
  }

  render() {
    const { value } = this.state;
    return (
      <StepWrapper>
        <div className="step-title">
          <Title level={3}>
          Wie viel dürfen wir für <br /> Sie umziehen?
          </Title>
        </div>

        <div className="input-wrapper">
          <Radio.Group onChange={this.onChange} value={value}>
            <Radio value={true}>Ich habe mein Volumen berechnet.</Radio><br/>
            <Radio value={false}>Ich fülle lieber eine Liste aus.</Radio>
          </Radio.Group>
          { this.state.showRequiredValidation && <div>
              <Text type="danger">Bitte treffen Sie eine Auswahl.</Text>
            </div>
          }
        </div>
      </StepWrapper>
    );
  }
}

export default Step4;

const StepWrapper = styled.div`
  
  
`